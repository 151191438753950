import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const ClinicForm = ({ item, handleInputChange, zipcode }) => {
    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Razão Social
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="legalName"
                            defaultValue={item.legalName || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CNPJ
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="cnpj"
                            defaultValue={item.cnpj || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nome Fantasia
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            defaultValue={item.fullName || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            defaultValue={item.phone || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CEP
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="zipcode"
                            defaultValue={zipcode || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Rua
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressStreet"
                            defaultValue={item.addressStreet || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Número
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="addressNumber"
                            defaultValue={item.addressNumber || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Bairro
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressNeighborhood"
                            defaultValue={item.addressNeighborhood || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Número
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="addressNumber"
                            defaultValue={item.addressNumber || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Bairro
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressCity"
                            defaultValue={item.addressCity || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Complemento
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressComplement"
                            defaultValue={item.addressComplement || ""}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Cidade
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressCity"
                            defaultValue={item.addressCity || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            UF
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="addressState"
                            onChange={handleInputChange}
                            value={item.addressState || ""}
                            required
                        >
                            <option value="" disabled></option>
                            {[
                                "AC",
                                "AL",
                                "AP",
                                "AM",
                                "BA",
                                "CE",
                                "DF",
                                "ES",
                                "GO",
                                "MA",
                                "MT",
                                "MS",
                                "MG",
                                "PA",
                                "PB",
                                "PR",
                                "PE",
                                "PI",
                                "RJ",
                                "RN",
                                "RS",
                                "RO",
                                "RR",
                                "SC",
                                "SP",
                                "SE",
                                "TO",
                            ].map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default ClinicForm;
