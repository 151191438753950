import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {
    dateFormat,
    dateHourFormat,
    getInitials,
} from "../../../../../config/utils";
import patientService from "../../../../../services/clinicPatientService";
import roleService from "../../../../../services/clinicRoleService";
import { ButtonPrimary } from "../../../../Buttons";

const ProfessionalsData = ({ professional, setLoading, updateProfessionalDetails }) => {
    const [show, setShow] = useState(false);
    const [patients, setPatients] = useState([]);
    const [selectedPatientId, setSelectedPatientId] = useState("");
    const [buttonText, setButtonText] = useState("Adicionar");

    useEffect(() => {
        if (professional && professional.patients) {
            const isPatientAssigned = professional.patients.some(
                (patient) => patient._id === selectedPatientId
            );
            setButtonText(isPatientAssigned ? "Remover" : "Adicionar");
        }
    }, [selectedPatientId, professional]);

    const getPatients = async () => {
        setShow(true);
        setLoading(true);
        setSelectedPatientId("");
        try {
            const response = await patientService.getAll();
            setPatients(response);
        } catch (error) {
            console.log(error);
            setShow(false);
        }
        setLoading(false);
    };

    const attachPatient = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await roleService.attachPatient(professional._id, {
                patientId: selectedPatientId,
            });
            updateProfessionalDetails(response.role);
            setShow(false);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center"
                >
                    <h2>{professional.user && professional.user.fullName}</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">E-mail:</p>
                    <small>
                        {professional.user && professional.user.email}
                    </small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">CPF:</p>
                    <small>{professional.user && professional.user.cpf}</small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">Telefone:</p>
                    <small>
                        {professional.user && professional.user.phone}
                    </small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">Data de Nascimento:</p>
                    <small>
                        {professional.user &&
                            dateFormat(professional.user.birthDate)}
                    </small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">Último Login:</p>
                    <small>
                        {professional.user &&
                            dateHourFormat(professional.user.lastLogin)}
                    </small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">Profissão:</p>
                    <small>{professional.profession}</small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className="mb-0 fw-bold">Tipo de Usuário:</p>
                    <small>
                        {professional.user && professional.user.type === "admin"
                            ? "Administrador"
                            : "Profissional"}
                    </small>
                </Col>
                <Col xs={12} md={3} className="mb-3">
                    <p className={`mb-0 fw-bold`}>Status:</p>
                    <small
                        className={`text-${
                            professional.status === "active"
                                ? "success"
                                : "danger"
                        } fw-bold`}
                    >
                        {(professional.status === "active" && "Ativo") ||
                            (professional.status === "inactive" && "Inativo") ||
                            (professional.status === "deleted" && "Removido")}
                    </small>
                </Col>
                <Col xs={12} className="mb-3">
                    <p className="mb-0 fw-bold">Pacientes:</p>
                    <small>
                        {professional.patients &&
                        professional.patients.length > 0 ? (
                            professional.patients.map((patient) => (
                                <OverlayTrigger
                                    key={patient._id}
                                    placement="top"
                                    overlay={
                                        <Tooltip>{patient.fullName}</Tooltip>
                                    }
                                >
                                    <img
                                        src={`https://fakeimg.pl/50x50/124237/ffd1ba/?text=${getInitials(
                                            patient.fullName
                                        )}&font_size=35`}
                                        alt=""
                                        className="img-fluid mb-2 me-2 rounded c-pointer"
                                        onClick={() => getPatients()}
                                    />
                                </OverlayTrigger>
                            ))
                        ) : (
                            <>
                                <small
                                    className="fw-bold c-pointer color-primary text-uppercase"
                                    onClick={() => getPatients()}
                                >
                                    Adicionar Pacientes
                                </small>
                            </>
                        )}
                    </small>
                </Col>
            </Row>
            <Modal
                size="sm"
                className="patients-modal"
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <Form onSubmit={attachPatient}>
                        <Form.Select
                            className="form-control mb-2"
                            onChange={(event) =>
                                setSelectedPatientId(event.target.value)
                            }
                            value={selectedPatientId || ""}
                        >
                            <option disabled value="">
                                Selecione um paciente
                            </option>
                            {patients.map((patient) => (
                                <option key={patient._id} value={patient._id}>
                                    {patient.fullName}
                                </option>
                            ))}
                        </Form.Select>
                        <ButtonPrimary
                            type="submit"
                            className="w-100"
                            btnText={buttonText}
                            disabled={selectedPatientId === ""}
                        />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProfessionalsData;
