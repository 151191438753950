import React from "react";
import { Navigate  } from "react-router-dom";
import { useAuth } from "../context/authContext";

function PrivateRoute({
    children,
    adminOnly = false,
    requireClinic = false,
    clinicAdminOnly = false,
    requireOwner = false,
}) {
    const { user, selectedClinic } = useAuth();

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    if (adminOnly && user.role !== "admin") {
        return <Navigate to="/dashboard" replace />;
    }

    if (requireClinic && !selectedClinic) {
        return <Navigate to="/dashboard" replace />;
    }

    if (
        clinicAdminOnly &&
        !(
            user?.clinicRole === "admin" ||
            user?.clinicRole === "owner" ||
            user.role === "admin"
        )
    ) {
        return <Navigate to="/dashboard" replace />;
    }

    if (
        requireOwner &&
        !(user?.clinicRole === "owner" || user.role === "admin")
    ) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
}

export default PrivateRoute;
