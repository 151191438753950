import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";

function Dashboard() {
    useEffect(() => {
        document.title = "We Care Sync - Dashboard";
    }, []);

    return (
        <>
            <Sidebar pageName="Dashboard" pageUrl="/dashboard">
                <Container fluid>
                    <Row className="my-2 mx-2 mx-md-4">
                    </Row>
                </Container>
            </Sidebar>
        </>
    );
}

export default Dashboard;
