import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { CustomModal } from "../../../components/Modal";
import UserForm from "../../../components/Forms/Dashboard/Users";
import FilterBar from "../../../components/FiltersBar";
import userService from "../../../services/userService";
import Swal from "sweetalert2";

function Users() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataInfo, setDataInfo] = useState({});
    const [filters, setFilters] = useState({
        page: 1,
        sort: { sortBy: "createdAt", order: "desc" },
        limit: 20,
        search: "",
        status: "all",
        userType: "",
    });
    const [user, setUser] = useState({
        fullName: "",
        email: "",
        role: "",
        password: "",
        cpf: "",
    });
    // const [showModalView, setShowModalView] = useState(false);
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const filterConfig = [
        {
            type: "text",
            label: "Pesquisar pelo nome do usuário",
            name: "search",
            size: 3,
        },
        {
            type: "select",
            label: "Filtrar por status",
            name: "status",
            options: [
                { label: "Todos", value: "all" },
                { label: "Ativos", value: "active" },
                { label: "Inativos", value: "inactive" },
            ],
            size: 2,
        },
        {
            type: "select",
            label: "Tipo de usuário",
            name: "userType",
            options: [
                { label: "Todos", value: "" },
                { label: "Administrador", value: "admin" },
                { label: "Usuário", value: "user" },
                { label: "Paciente", value: "patient" },
            ],
            size: 2,
        },
        {
            type: "select",
            label: "# itens",
            name: "limit",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
            ],
            size: 1,
        },
    ];

    const refreshItems = async () => {
        setshowModalEditAdd(false);
        setLoading(false);
        setUser({});
        setErrorMessage("");
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const { page, sort, limit, search, status, userType } = filters;
            const params = {
                page,
                sortBy: sort.sortBy,
                order: sort.order,
                limit,
                search,
                status,
                userType,
            };
            const response = await userService.fetchData(params);
            setData(response.docs);
            setDataInfo({ ...response });
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        } finally {
            refreshItems();
        }
    }, [filters]);

    useEffect(() => {
        document.title = "We Care Sync - Usuários";
        fetchUsers();
    }, [fetchUsers]);

    const handleSort = (sortKey) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                sort: {
                    sortBy: sortKey,
                    order:
                        prevFilters.sort.sortBy === sortKey &&
                        prevFilters.sort.order === "asc"
                            ? "desc"
                            : "asc",
                },
            };
            return newFilters;
        });
    };

    const prevPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
        }));
    };

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page < dataInfo.pages
                    ? prevFilters.page + 1
                    : prevFilters.page,
        }));
    };

    const handleInputSearch = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    const submitData = async () => {
        setLoading(true);
        try {
            if (user._id) {
                await userService.submitData("update", user);
            } else {
                await userService.submitData("add", user);
            }
            fetchUsers();
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
        } finally {
            setLoading(false);
        }
    };

    const deleteData = async (data) => {
        const result = await Swal.fire({
            title: "Atenção!",
            text: `Deseja realmente ${
                data.status === "active" ? "inativar" : "ativar"
            } o usuário?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                await userService.deleteData(data._id);
                fetchUsers();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao ${
                        user.status === "active" ? "inativar" : "ativar"
                    } o usuário, tente novamente mais tarde.`,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Sidebar pageName="Usuários" pageUrl="/users">
                <Container fluid>
                    <FilterBar
                        config={filterConfig}
                        filters={filters}
                        onFilterChange={handleInputSearch}
                        onAddClick={() => {
                            setUser({});
                            setshowModalEditAdd(true);
                        }}
                    />
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"fullName"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"email"}
                                        >
                                            E-mail
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={1}
                                            onSort={handleSort}
                                            sortKey={"status"}
                                            className="justify-content-center"
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Administrativo?
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Paciente?
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={1}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.fullName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"E-mail:"}
                                                value={data.email}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={1}
                                                className={`d-inline-flex align-items-center justify-content-center small ${
                                                    (data.status === "active" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "fw-bold text-danger")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    data.status === "active"
                                                        ? "Ativo"
                                                        : "Inativo"
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center justify-content-center fw-bold ${
                                                    data.role === "admin"
                                                        ? "color-primary"
                                                        : "text-danger"
                                                }`}
                                                title={"Administrativo?"}
                                                value={
                                                    data.role === "admin"
                                                        ? "Sim"
                                                        : "Não"
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center justify-content-center fw-bold ${
                                                    data.isPatient
                                                        ? "text-success"
                                                        : "text-danger"
                                                }`}
                                                title={"Paciente?"}
                                                value={
                                                    data.isPatient
                                                        ? "Sim"
                                                        : "Não"
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={1}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                {/* <Dropdown.Item
                                                    className="text-success font-semi-bold text-center"
                                                    onClick={() => {
                                                        setUser(data);
                                                        setShowModalView(true);
                                                    }}
                                                >
                                                    Ver informações
                                                </Dropdown.Item> */}
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setUser(data);
                                                        setshowModalEditAdd(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(data)
                                                    }
                                                >
                                                    {data.status === "active"
                                                        ? "Inativar usuário"
                                                        : "Ativar usuário"}
                                                </Dropdown.Item>
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
                <CustomModal
                    show={showModalEditAdd}
                    onHide={() => {
                        refreshItems();
                    }}
                    title={user._id ? "Editar Usuário" : "Adicionar Usuário"}
                    submitLabel={
                        user._id ? "Salvar Alterações" : "Adicionar Usuário"
                    }
                    onSubmit={submitData}
                    errorMessage={errorMessage}
                    type="form"
                >
                    <UserForm
                        user={user}
                        handleInputChange={handleInputChange}
                    />
                </CustomModal>
            </Sidebar>
            {/* <CustomModal
                show={showModalView}
                onHide={() => {
                    refreshItems();
                }}
                title={"Visualizar Usuário"}
                type="view"
                data={user}
            >
                <Row>
                    <Col xs={12} sm={3} className="mb-3">
                        <h6 className="fw-bold">Nome</h6>
                        <p className="text-muted">{user.fullName}</p>
                    </Col>
                    <Col xs={12} sm={3} className="mb-3">
                        <h6 className="fw-bold">Nome</h6>
                        <p className="text-muted">{user.fullName}</p>
                    </Col>
                    <Col xs={12} sm={3} className="mb-3">
                        <h6 className="fw-bold">Nome</h6>
                        <p className="text-muted">{user.fullName}</p>
                    </Col>
                    <Col xs={12} sm={3} className="mb-3">
                        <h6 className="fw-bold">Nome</h6>
                        <p className="text-muted">{user.fullName}</p>
                    </Col>
                </Row>
            </CustomModal> */}
            <Loading show={loading} />
        </>
    );
}

export default Users;
