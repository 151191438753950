import api from "../config/api.js";

const fetchData = async ({ page, sortBy, order, limit, search, status }) => {
    const response = await api.get(
        `/clinic-patients?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}`
    );
    return response.data;
};

const getAll = async () => {
    const response = await api.get("/clinic-patients");
    return response.data;
};

const submitData = async (data) => {
    if (!data._id) {
        try {
            const response = await api.post("/clinic-patients", data);
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    } else {
        try {
            const response = await api.put(
                `/clinic-patients/${data._id}`,
                data
            );
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    }
};

const showData = async (id) => {
    const response = await api.get(`/clinic-patients/${id}`);
    return response.data;
};

const updateData = async (data) => {
    const response = await api.put(`/clinic-patients/${data._id}`, data);
    return response.data;
};

const deleteData = async (id) => {
    try {
        const response = await api.delete(`/clinic-patients/${id}`);
        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error.response.data;
    }
};

const apiMethods = {
    fetchData,
    getAll,
    submitData,
    showData,
    updateData,
    deleteData,
};
export default apiMethods;
