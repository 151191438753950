import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
    cpfFormat,
    cnpjFormat,
    normalizeDocumentNumber,
} from "../../../../config/utils";

const InviteClinicForm = ({ item, handleInputChange, zipcode }) => {
    return (
        <>
            <h5 className="text-center mb-2">Dados da Clínica</h5>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">CNPJ</Form.Label>
                        <Form.Control
                            type="text"
                            name="cnpj"
                            value={cnpjFormat(item.cnpj) || ""}
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Razão Social
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="legalName"
                            defaultValue={item.legalName || ""}
                            readOnly
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nome Fantasia
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            defaultValue={item.fullName || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone da Clínica
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="phone"
                            defaultValue={item.phone || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CEP
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="zipcode"
                            defaultValue={zipcode || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Rua
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressStreet"
                            defaultValue={item.addressStreet || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Número
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="addressNumber"
                            defaultValue={item.addressNumber || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Bairro
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressNeighborhood"
                            defaultValue={item.addressNeighborhood || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Complemento
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressComplement"
                            defaultValue={item.addressComplement || ""}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Cidade
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="addressCity"
                            defaultValue={item.addressCity || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            UF
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="addressState"
                            onChange={handleInputChange}
                            value={item.addressState || ""}
                            required
                        >
                            <option value="" disabled></option>
                            {[
                                "AC",
                                "AL",
                                "AP",
                                "AM",
                                "BA",
                                "CE",
                                "DF",
                                "ES",
                                "GO",
                                "MA",
                                "MT",
                                "MS",
                                "MG",
                                "PA",
                                "PB",
                                "PR",
                                "PE",
                                "PI",
                                "RJ",
                                "RN",
                                "RS",
                                "RO",
                                "RR",
                                "SC",
                                "SP",
                                "SE",
                                "TO",
                            ].map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

const InviteClinicUserForm = ({
    item,
    handleInputChange,
    hasAcceptedTerms,
    setHasAcceptedTerms,
}) => {
    return (
        <>
            <h5 className="text-center mb-2">Dados do Proprietário</h5>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">Nome</Form.Label>
                        <Form.Control
                            type="text"
                            name="userName"
                            value={item.userName || ""}
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            E-mail
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            defaultValue={item.email || ""}
                            readOnly
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CPF
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="cpf"
                            defaultValue={
                                item.cpf &&
                                normalizeDocumentNumber(item.cpf).length === 11
                                    ? cpfFormat(item.cpf)
                                    : ""
                            }
                            readOnly={
                                item.cpf &&
                                normalizeDocumentNumber(item.cpf).length === 11
                            }
                            onChange={(e) => handleInputChange(e)}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Data de Nascimento
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="date"
                            name="birthDate"
                            defaultValue={item.birthDate || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone do Proprietário
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="phoneUser"
                            defaultValue={item.phoneUser || ""}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Senha
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={item.password || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Confirmação de Senha
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            defaultValue={item.confirmPassword || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <small className="color-light-gray">
                    Os campos marcados com um
                    <strong className="ms-1 text-danger fw-bold">*</strong> são
                    de preenchimento obrigatório. Não se esqueça de
                    preenchê-los.
                    <br />
                    Certifique-se de revisar e confirmar as informações
                    inseridas no formulário a cima, garantindo que estejam
                    corretas e verdadeiras.
                </small>
                <Col xs={12} className="d-flex justify-content-center mt-4">
                    <Form.Group className="mb-2">
                        <Form.Check
                            type="checkbox"
                            label={
                                <span>
                                    Ao marcar esta opção, você concorda com os{" "}
                                    <a
                                        href="/#"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <strong>Termos & Condições</strong>
                                    </a>{" "}
                                    da plataforma.
                                </span>
                            }
                            onChange={(e) =>
                                setHasAcceptedTerms(e.target.checked)
                            }
                            checked={hasAcceptedTerms}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};



export { InviteClinicForm, InviteClinicUserForm };
