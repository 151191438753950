import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Alert, Form } from "react-bootstrap";
import Loading from "../../../components/Loading";
import authService from "../../../services/authService";
import configService from "../../../services/configService";
import inviteService from "../../../services/inviteService";
import {
    ButtonPrimary,
    ButtonCallToACtion,
    ButtomCustom,
} from "../../../components/Buttons";
import {
    InviteClinicForm,
    InviteClinicUserForm,
} from "../../../components/Forms/Invites/Clinics";

function Invites() {
    const [isLoading, setIsLoading] = useState(false);
    const [invitationInfo, setInvitationInfo] = useState({});
    const [clinicInfo, setClinicInfo] = useState(true);
    const [zipcode, setZipcode] = useState("");
    const [item, setItem] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorCard, setErrorCard] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Erro ao buscar convite.");
    const [errorCardMessage, setErrorCardMessage] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

    const { token } = useParams();

    useEffect(() => {
        document.title = "We Care Sync - Convite";

        const checkAuth = async () => {
            if (localStorage.getItem("isAuthenticated") === "true") {
                window.location.href = "/dashboard";
            }
        };

        const searchByToken = async (token) => {
            setIsLoading(true);
            await authService
                .getInvitationInfo(token)
                .then((response) => {
                    setInvitationInfo(response);
                    let item = {
                        cnpj: response.cnpj,
                        legalName: response.legalName,
                        userName: response.userName,
                        email: response.email,
                    };
                    if (response.cpf) {
                        item.cpf = response.cpf;
                    }
                    setItem(item);
                })
                .catch((error) => {
                    setError(true);
                    setErrorMessage(error);
                });
            setIsLoading(false);
        };

        checkAuth();
        searchByToken(token);
    }, [token]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        if (name === "zipcode") {
            const cleanedValue = value.replace(/[^0-9]/g, "").slice(0, 8);
            setZipcode(cleanedValue);

            if (cleanedValue.length === 8) {
                setIsLoading(true);
                try {
                    const response = await configService.getAddressByZipcode(
                        cleanedValue
                    );
                    if (!response.erro) {
                        setItem((prevItem) => ({
                            ...prevItem,
                            addressStreet: response.logradouro,
                            addressNeighborhood: response.bairro,
                            addressCity: response.localidade,
                            addressState: response.uf,
                            addressZipCode: cleanedValue,
                        }));
                    } else {
                        setErrorMessage("CEP não encontrado");
                    }
                } catch (error) {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            } else {
                setErrorMessage("");
                setItem((prevItem) => ({
                    ...prevItem,
                    addressStreet: "",
                    addressNeighborhood: "",
                    addressCity: "",
                    addressState: "",
                }));
            }
        } else {
            setItem((prevItem) => ({
                ...prevItem,
                [name]: value,
            }));
        }
    };

    const submitData = async () => {
        setIsLoading(true);
        setErrorCard(false);
        setErrorCardMessage("");
        try {
            if (!clinicInfo) {
                if (item.password !== item.confirmPassword) {
                    setErrorCard(true);
                    setErrorCardMessage("Senhas não conferem");
                    setIsLoading(false);
                } else if (!hasAcceptedTerms) {
                    setErrorCard(true);
                    setErrorCardMessage(
                        "Você deve aceitar os termos e condições para se registrar."
                    );
                    setIsLoading(false);
                    return;
                } else {
                    await inviteService
                        .acceptInvitation(invitationInfo._id, item)
                        .then(() => {
                            setSuccess(true);
                        })
                        .catch((error) => {
                            setErrorCard(true);
                            setErrorCardMessage(error.message);
                        });
                }
            } else {
                setClinicInfo(false);
            }
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-color-primary">
            <Row className="min-vh-100 m-0">
                <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center align-items-center"
                >
                    <Card className="card-form py-1 px-2 card-invitation">
                        {!invitationInfo.userExists && (
                            <Card.Body className="body-invitation">
                                {!error ? (
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <h4>
                                                Convite para a clínica{" "}
                                                <strong className="color-primary">
                                                    {invitationInfo.legalName}
                                                </strong>
                                            </h4>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    submitData();
                                                }}
                                                className="mt-4 text-start"
                                            >
                                                {error && (
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            className="d-flex justify-content-center"
                                                        >
                                                            <Alert
                                                                variant="danger"
                                                                className="mb-0 text-center small w-auto"
                                                            >
                                                                {errorMessage}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {clinicInfo ? (
                                                    <InviteClinicForm
                                                        item={item}
                                                        handleInputChange={
                                                            handleInputChange
                                                        }
                                                        zipcode={zipcode}
                                                    />
                                                ) : (
                                                    !success && (
                                                        <InviteClinicUserForm
                                                            item={item}
                                                            handleInputChange={
                                                                handleInputChange
                                                            }
                                                            hasAcceptedTerms={
                                                                hasAcceptedTerms
                                                            }
                                                            setHasAcceptedTerms={
                                                                setHasAcceptedTerms
                                                            }
                                                        />
                                                    )
                                                )}
                                                {errorCard && (
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            className="d-flex justify-content-center"
                                                        >
                                                            <Alert variant="danger">
                                                                {
                                                                    errorCardMessage
                                                                }
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row>
                                                    {!success && (
                                                        <>
                                                            {clinicInfo ? (
                                                                <Col
                                                                    xs={12}
                                                                    className="d-flex justify-content-center"
                                                                >
                                                                    <ButtonPrimary
                                                                        type="submit"
                                                                        btnText={
                                                                            "Próximo"
                                                                        }
                                                                    />
                                                                </Col>
                                                            ) : (
                                                                <Col
                                                                    xs={12}
                                                                    className="d-flex justify-content-between"
                                                                >
                                                                    <ButtonCallToACtion
                                                                        type="button"
                                                                        btnText={
                                                                            "Voltar"
                                                                        }
                                                                        onClick={() => {
                                                                            setClinicInfo(
                                                                                true
                                                                            );
                                                                        }}
                                                                    />
                                                                    <ButtonPrimary
                                                                        type="submit"
                                                                        btnText={
                                                                            "Aceitar convite"
                                                                        }
                                                                    />
                                                                </Col>
                                                            )}
                                                        </>
                                                    )}
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="d-flex justify-content-center align-items-center mb-5">
                                                <span className="material-icons text-danger text-center fw-bold">
                                                    warning
                                                </span>
                                            </div>
                                            <h4 className="fw-bold">
                                                {errorMessage}
                                            </h4>
                                            <h5>
                                                Por favor, entre em contato com
                                                o suporte.
                                            </h5>
                                        </Col>
                                    </Row>
                                )}
                                {success && (
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="d-flex justify-content-center align-items-center mb-5">
                                                <span className="material-icons text-success text-center fw-bold">
                                                    check_circle
                                                </span>
                                            </div>
                                            <h4 className="fw-bold">
                                                Seu acesso foi criado! <br />
                                                Foram enviadas as credenciais
                                                para seu e-mail, e agora poderá
                                                acessar o sistema.
                                            </h4>
                                            <div className="d-flex justify-content-center align-items-center mt-4">
                                                <ButtomCustom
                                                    type="button"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "/login")
                                                    }
                                                    btnText="ir para login"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        )}
                        <Card.Footer className="bg-color-white border-0 text-center">
                            <small className="color-dark-gray text-center mt-5">
                                © {new Date().getFullYear()} - We Care Sync.
                                Todos os Direitos Reservados. Criado por{" "}
                                <a
                                    className="color-dark-gray font-semi-bold text-decoration-none"
                                    href="https://starchild.com.br"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    starchild
                                </a>
                                .
                            </small>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Loading show={isLoading} />
        </div>
    );
}

export default Invites;
