import api from "../config/api.js";

const getAddressByZipcode = async (zipcode) => {
    try {
        const response = await api.get(`/cep/${zipcode}`);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
}

const apiMethods = { getAddressByZipcode };
export default apiMethods;