import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { isUserMinor, formatDateForInput } from "../../../../../config/utils";

const PatientsForm = ({ patient, handleInputChange }) => {
    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nome
                            {!patient._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            value={patient.fullName || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Data de Nascimento
                            {!patient._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="date"
                            name="birthDate"
                            value={patient._id ? formatDateForInput(patient.birthDate) : patient.birthDate || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            E-mail
                            {!patient._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            value={patient.email || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone
                            {!patient._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="phone"
                            value={patient.phone || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Custo por Sessão
                            {!patient._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="sessionCost"
                            value={patient.sessionCost || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                {isUserMinor(patient.birthDate) && (
                    <>
                        <h5 className="fw-bold text-uppercase mt-4 mb-2">
                            Dados do Responsável Legal
                        </h5>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 mb-md-2">
                                <Form.Label className="text-uppercase">
                                    Nome do Responsável
                                    {!patient._id &&
                                        isUserMinor(patient.birthDate) && (
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        )}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="guardianName"
                                    value={patient.guardianName || ""}
                                    onChange={handleInputChange}
                                    required={
                                        !patient.birthDate &&
                                        isUserMinor(patient.birthDate)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3 mb-md-2">
                                <Form.Label className="text-uppercase">
                                    CPF do Responsável
                                    {!patient._id &&
                                        isUserMinor(patient.birthDate) && (
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        )}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="guardianCpf"
                                    value={patient.guardianCpf || ""}
                                    onChange={handleInputChange}
                                    required={
                                        !patient.birthDate &&
                                        isUserMinor(patient.birthDate)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};

export default PatientsForm;
