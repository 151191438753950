import api from "../config/api.js";

const acceptInvitation = async (id, item) => {
    try {
        const response = await api.put(`/invitations/${id}/accept`, item);
        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error.response.data;
    }
};

const userAcceptInvitation = async (id, item) => {
    try {
        const response = await api.put(`/users-invitations/${id}/accept`, item);
        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error.response.data;
    }
};

const apiMethods = { acceptInvitation, userAcceptInvitation };
export default apiMethods;
