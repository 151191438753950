import api from "../config/api.js";

const fetchData = async () => {
    try {
        const response = await api.get("/clinic-schedules");
        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error.response.data;
    }
};

const submitData = async (data) => {
    if (!data._id) {
        try {
            const response = await api.post("/clinic-schedules", data);
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    } else {
        try {
            const response = await api.put(
                `/users-invitations/${data._id}`,
                data
            );
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    }
};

const apiMethods = {
    fetchData,
    submitData,
};
export default apiMethods;
