import React from "react";
import { Row, Col } from "react-bootstrap";
import {
    CardListHeader,
    CardListHeaderSortItem,
    CardListHeaderItem,
    CardListBody,
} from "../CardList";

const DataList = ({ items, headers, renderItem }) => {
    return (
        <>
            <CardListHeader className="bg-color-light-gray">
                <Row>
                    {headers.map((header, index) =>
                        header.sortKey ? (
                            <CardListHeaderSortItem
                                key={index}
                                className={header.className}
                                xs={header.xs}
                                sm={header.sm}
                                md={header.md}
                                lg={header.lg}
                                sortKey={header.sortKey}
                                onSort={() => header.onSort(header.sortKey)}
                            >
                                {header.label}
                            </CardListHeaderSortItem>
                        ) : (
                            <CardListHeaderItem
                                key={index}
                                className={header.className}
                                xs={header.xs}
                                sm={header.sm}
                                md={header.md}
                                lg={header.lg}
                            >
                                {header.label}
                            </CardListHeaderItem>
                        )
                    )}
                </Row>
            </CardListHeader>
            {items.length === 0 ? (
                <CardListBody>
                    <Col
                        xs={12}
                        className="d-flex align-items-center justify-content-center text-muted small"
                    >
                        Nenhum item encontrado.
                    </Col>
                </CardListBody>
            ) : (
                items.map(renderItem)
            )}
        </>
    );
};

export default DataList;
