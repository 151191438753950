import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { cpfFormat, normalizeDocumentNumber } from "../../../../config/utils";

const InviteUserForm = ({
    item,
    handleInputChange,
    hasAcceptedTerms,
    setHasAcceptedTerms,
}) => {
    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">Nome</Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            value={item.fullName || ""}
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            E-mail
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            defaultValue={item.email || ""}
                            readOnly
                            disabled
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CPF
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="cpf"
                            defaultValue={
                                item.cpf &&
                                normalizeDocumentNumber(item.cpf).length === 11
                                    ? cpfFormat(item.cpf)
                                    : ""
                            }
                            required
                            onChange={(e) => handleInputChange(e)}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Data de Nascimento
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="date"
                            name="birthDate"
                            defaultValue={item.birthDate || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="phoneUser"
                            defaultValue={item.phoneUser || ""}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Senha
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={item.password || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Confirmação de Senha
                            <sup className="ms-1 text-danger fw-bold">*</sup>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            defaultValue={item.confirmPassword || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <small className="color-light-gray">
                    Os campos marcados com um
                    <strong className="ms-1 text-danger fw-bold">*</strong> são
                    de preenchimento obrigatório. Não se esqueça de
                    preenchê-los.
                    <br />
                    Certifique-se de revisar e confirmar as informações
                    inseridas no formulário a cima, garantindo que estejam
                    corretas e verdadeiras.
                </small>
                <Col xs={12} className="d-flex justify-content-center mt-4">
                    <Form.Group className="mb-2">
                        <Form.Check
                            type="checkbox"
                            label={
                                <span>
                                    Ao marcar esta opção, você concorda com os{" "}
                                    <a
                                        href="/#"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <strong>Termos & Condições</strong>
                                    </a>{" "}
                                    da plataforma.
                                </span>
                            }
                            onChange={(e) =>
                                setHasAcceptedTerms(e.target.checked)
                            }
                            checked={hasAcceptedTerms}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export { InviteUserForm };
