import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { CustomModal } from "../../../../components/Modal";
import ScheduleForm from "../../../../components/Forms/Dashboard/_clinics/Schedule";
import scheduleService from "../../../../services/clinicScheduleService";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import Swal from "sweetalert2";

function Schedule() {
    const [loading, setLoading] = useState(false);
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setData] = useState([]);
    const [item, setItem] = useState({
        timeOfSession: "",
        startTime: "",
        endTime: "",
        lunchStartTime: "",
        lunchEndTime: "",
        lunchTime: false,
    });
    const [selectedDays, setSelectedDays] = useState([]);

    const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
    const formattedStart = format(startOfThisWeek, "dd'/'MMMM", {
        locale: ptBR,
    });
    const formattedEnd = format(endOfThisWeek, "dd'/'MMMM", { locale: ptBR });
    const weekDays = [
        { id: "monday", namePt: "Segunda-feira" },
        { id: "tuesday", namePt: "Terça-feira" },
        { id: "wednesday", namePt: "Quarta-feira" },
        { id: "thursday", namePt: "Quinta-feira" },
        { id: "friday", namePt: "Sexta-feira" },
        { id: "saturday", namePt: "Sábado" },
        { id: "sunday", namePt: "Domingo" },
    ];

    const refreshItems = async () => {
        setshowModalEditAdd(false);
        setLoading(false);
        setItem({});
        setSelectedDays([]);
        setErrorMessage("");
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            await scheduleService
                .fetchData()
                .then((result) => {
                    setLoading(false);
                    setData(result);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } catch (error) {
            console.error("Erro ao buscar horários:", error);
            setLoading(false);
        }
        await refreshItems();
    }, []);

    useEffect(() => {
        document.title = "We Care Sync - Agenda";
        fetchData();
    }, [fetchData]);

    const toggleDaySelection = (dayId) => {
        const updatedSelectedDays = selectedDays.includes(dayId)
            ? selectedDays.filter((day) => day !== dayId)
            : [...selectedDays, dayId];
        setSelectedDays(updatedSelectedDays);
    };

    const handleInputChange = (event) => {
        const { name, type } = event.target;
        const value =
            type === "checkbox" ? event.target.checked : event.target.value;

        if (name === "timeOfSession") {
            const numericValue = parseInt(value, 10);
            if (isNaN(numericValue)) return;
            setItem({ ...item, [name]: numericValue > 60 ? 60 : numericValue });
        } else {
            setItem({ ...item, [name]: value });
        }
    };

    const submitData = async () => {
        setLoading(true);
        item.weekDays = selectedDays;
        try {
            await scheduleService.submitData(item);
            Swal.fire("Sucesso!", "Horário adicionado com sucesso!", "success");
            fetchData();
        } catch (error) {
            setErrorMessage(error.message);
            Swal.fire("Erro!", error.message, "error");
        }
    };

    return (
        <>
            <Sidebar pageName="Agenda" pageUrl="/agenda">
                <Container fluid className="px-4">
                    <Row className="mt-2 mb-4">
                        <Col
                            xs={12}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btn-add-solo solo-attach me-3"
                                onClick={() => {
                                    alert("Em desenvolvimento");
                                }}
                            >
                                Atrelar Horários
                            </Button>
                            <Button
                                className="custom-btn btn-app btn-add-solo"
                                onClick={() => {
                                    setshowModalEditAdd(true);
                                    setItem({});
                                    setSelectedDays([]);
                                    setErrorMessage("");
                                }}
                            >
                                Adicionar Horários
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h3 className="text-center">
                                de{" "}
                                <span className="color-primary fw-bold">
                                    {formattedStart}
                                </span>{" "}
                                até{" "}
                                <span className="color-primary fw-bold">
                                    {formattedEnd}
                                </span>
                            </h3>
                        </Col>
                        {data && Object.keys(data).length > 1 ? (
                            weekDays.map(
                                (day) =>
                                    data[day.id] &&
                                    data[day.id].length > 0 && (
                                        <Col key={day.id}>
                                            <Card>
                                                <Card.Header>
                                                    <h5 className="text-center">
                                                        {day.namePt}
                                                    </h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    {data[day.id].map(
                                                        (session, index) => (
                                                            <p
                                                                key={index}
                                                            >{`${session.startTime} - ${session.endTime}`}</p>
                                                        )
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                            )
                        ) : (
                            <Col
                                xs={12}
                                className="d-flex align-items-center justify-content-center text-muted small"
                            >
                                Não há horários cadastrados.
                            </Col>
                        )}
                    </Row>
                </Container>
                <CustomModal
                    show={showModalEditAdd}
                    onHide={() => {
                        refreshItems();
                    }}
                    title={"Adicionar Horários"}
                    submitLabel={"Enviar informações"}
                    onSubmit={submitData}
                    errorMessage={errorMessage}
                    disabled={selectedDays.length > 0 ? false : true}
                    type="form"
                >
                    <Row className="mb-4">
                        <Col xs={12} className="d-flex">
                            <h6 className="fw-bold">
                                Selecione os dias da semana:
                            </h6>
                        </Col>
                        {weekDays.map((day) => (
                            <Col
                                xs={12}
                                md={"auto"}
                                key={day.id}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <div
                                    className={`label-day ${
                                        selectedDays.includes(day.id)
                                            ? "selected"
                                            : ""
                                    }`}
                                    onClick={() => toggleDaySelection(day.id)}
                                >
                                    {day.namePt}
                                </div>
                            </Col>
                        ))}
                    </Row>
                    {selectedDays.length > 0 && (
                        <>
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <h6 className="fw-bold">
                                        Baseado nos dias selecionados, insira as
                                        informações abaixo:
                                    </h6>
                                </Col>
                            </Row>
                            <ScheduleForm
                                item={item}
                                handleInputChange={handleInputChange}
                                days={selectedDays}
                            />
                        </>
                    )}
                </CustomModal>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default Schedule;
