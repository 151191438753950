import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import {
    CardList,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../../components/CardList";
import { CustomModal } from "../../../../components/Modal";
import UserForm from "../../../../components/Forms/Dashboard/_clinics/UsersInvitations";
import ProfessionalsData from "../../../../components/Data/Dashboard/_clinics/Professionals";
import FilterBar from "../../../../components/FiltersBar";
import { useAuth } from "../../../../context/authContext";
import roleService from "../../../../services/clinicRoleService";
import { dateHourFormat } from "../../../../config/utils";
import DataList from "../../../../components/DataList";
import Swal from "sweetalert2";

function Professionals() {
    const { user: userInfo } = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [invites, setInvites] = useState([]);
    const [dataInfo, setDataInfo] = useState({});
    const [user, setUser] = useState({
        fullName: "",
        email: "",
        role: "",
        profession: "",
    });
    const [invitation, setInvitation] = useState({
        fullName: "",
        email: "",
        role: "",
        profession: "",
    });
    const [filters, setFilters] = useState({
        page: 1,
        sort: { sortBy: "createdAt", order: "desc" },
        limit: 20,
        search: "",
        status: "all",
    });
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    console.log(userInfo);

    const filterConfig = [
        {
            type: "text",
            label: "Pesquisar pelo nome do profissional",
            name: "search",
            size: 3,
        },
        {
            type: "select",
            label: "Filtrar por status",
            name: "status",
            options: [
                { label: "Todos", value: "all" },
                { label: "Ativos", value: "active" },
                { label: "Inativos", value: "inactive" },
            ],
            size: 2,
        },
        {
            type: "select",
            label: "# itens",
            name: "limit",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
            ],
            size: 1,
        },
    ];

    const refreshItems = async () => {
        setshowModalEditAdd(false);
        setShowModalView(false);
        setUser({});
        setInvitation({});
        setErrorMessage("");
        setLoading(false);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const { page, sort, limit, search, status, userType } = filters;
            const params = {
                page,
                sortBy: sort.sortBy,
                order: sort.order,
                limit,
                search,
                status,
                userType,
            };
            const response = await roleService.fetchData(params);
            const inv = await roleService.fetchInvites();
            setData(response.docs);
            setDataInfo({ ...response });
            setInvites(inv.invitations);
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        }
        await refreshItems();
    }, [filters]);

    useEffect(() => {
        document.title = "We Care Sync - Profissionais";
        fetchData();
    }, [fetchData]);

    const handleSort = (sortKey) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                sort: {
                    sortBy: sortKey,
                    order:
                        prevFilters.sort.sortBy === sortKey &&
                        prevFilters.sort.order === "asc"
                            ? "desc"
                            : "asc",
                },
            };
            return newFilters;
        });
    };

    const prevPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
        }));
    };

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page < dataInfo.pages
                    ? prevFilters.page + 1
                    : prevFilters.page,
        }));
    };

    const handleInputSearch = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleInputChange = (type) => (event) => {
        const { name, value } = event.target;
        if (type === "users") {
            setUser((prevItem) => ({
                ...prevItem,
                [name]: value,
            }));
        } else {
            setInvitation((prevItem) => ({
                ...prevItem,
                [name]: value,
            }));
        }
    };

    const submitData = async () => {
        setLoading(true);
        try {
            if (user._id) {
                await roleService.updateRole(user);
            } else {
                if (invitation._id) {
                    await roleService.submitInvite("update", invitation);
                } else {
                    await roleService.submitInvite("add", invitation);
                }
            }
            await fetchData();
            setLoading(false);
            setTimeout(() => {
                Swal.fire(
                    "Sucesso!",
                    "Operação realizada com sucesso.",
                    "success"
                );
            }, 300);
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
            setLoading(false);
        }
    };

    const deleteData = async (data, type) => {
        const result = await Swal.fire({
            title: "Atenção!",
            text: `Deseja realmente ${
                (type === "invite" && "cencelar") ||
                (type === "user" &&
                    (data.status === "active" ? "inativar" : "ativar"))
            } o ${
                (type === "invite" && "convite") ||
                (type === "user" && "profissional")
            }?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                if (type === "invite") {
                    await roleService.deleteInvite(data._id);
                } else {
                    await roleService.inactiveRole(data._id);
                }
                await fetchData();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao ${
                        user.status === "active" ? "inativar" : "ativar"
                    } o usuário, tente novamente mais tarde.`,
                });
                setLoading(false);
            }
        }
    };

    const resendInvite = async (data) => {
        const result = await Swal.fire({
            title: "Atenção!",
            text: `Deseja realmente reenvier o convite?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                await roleService.resendInvitation(data._id);
                await fetchData();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao reenvier o convite para o usuário, tente novamente mais tarde.`,
                });
                setLoading(false);
            }
        }
    };

    const renderItem = (item, index) => (
        <CardListBody key={index}>
            <Row>
                <CardListBodyItem
                    xs={12}
                    lg={3}
                    className="d-inline-flex align-items-center text-muted small"
                    title="Nome:"
                    value={item.user && item.user.fullName}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex align-items-center text-muted small"
                    title="E-mail:"
                    value={item.user && item.user.email}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex justify-content-center align-items-center text-muted small"
                    title="Profissão:"
                    value={item.profession}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex justify-content-center align-items-center text-muted small"
                    title="Data de Entrada:"
                    value={dateHourFormat(item.createdAt)}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className={`d-inline-flex justify-content-center align-items-center fw-bold small ${
                        item.status === "active"
                            ? "text-success"
                            : "text-danger"
                    }`}
                    title="Status:"
                    value={item.status === "active" ? "Ativo" : "Inativo"}
                />
                {(userInfo?.role === "admin" ||
                    userInfo?.clinicRole === "owner" ||
                    (item.role === "user" &&
                        userInfo?.clinicRole === "admin")) && (
                    <CardListBodyItemOptions
                        xs={12}
                        lg={1}
                        className="d-inline-flex align-items-center justify-content-center"
                    >
                        <Dropdown.Item
                            className="text-success font-semi-bold text-center"
                            onClick={() => {
                                setUser(item);
                                setShowModalView(true);
                            }}
                        >
                            Ver informações
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="text-primary font-semi-bold text-center"
                            onClick={() => {
                                setUser(item);
                                setshowModalEditAdd(true);
                            }}
                        >
                            Editar informações
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="text-danger font-semi-bold text-center"
                            onClick={() => deleteData(item, "user")}
                        >
                            {item.status === "active"
                                ? "Inativar usuário"
                                : "Ativar usuário"}
                        </Dropdown.Item>
                    </CardListBodyItemOptions>
                )}
            </Row>
        </CardListBody>
    );

    const renderInviteItem = (invite, index) => (
        <CardListBody key={index}>
            <Row>
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex align-items-center text-muted small"
                    title={"Nome:"}
                    value={invite.fullName}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex align-items-center text-muted small"
                    title={"E-mail:"}
                    value={invite.email}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex justify-content-center align-items-center text-muted small"
                    title={"Data do Convite:"}
                    value={dateHourFormat(invite.createdAt)}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className="d-inline-flex justify-content-center align-items-center text-muted small"
                    title={"Data de Expiração:"}
                    value={dateHourFormat(invite.expiration)}
                />
                <CardListBodyItem
                    xs={12}
                    lg={2}
                    className={`d-inline-flex justify-content-center align-items-center fw-bold small ${
                        new Date(invite.expiration) > new Date()
                            ? (invite.status === "pending" && "text-warning") ||
                              (invite.status === "rejected" && "text-danger")
                            : "text-danger"
                    }`}
                    title={"Status:"}
                    value={
                        new Date(invite.expiration) > new Date()
                            ? (invite.status === "pending" && "Pendente") ||
                              (invite.status === "rejected" && "Rejeitado")
                            : "Convite Expirado"
                    }
                />
                <CardListBodyItemOptions
                    xs={12}
                    lg={2}
                    className="d-inline-flex align-items-center justify-content-center"
                >
                    <Dropdown.Item
                        className="text-primary font-semi-bold text-center"
                        onClick={() => {
                            setInvitation(invite);
                            setshowModalEditAdd(true);
                        }}
                    >
                        Editar informações
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="text-success font-semi-bold text-center"
                        onClick={() => resendInvite(invite)}
                    >
                        Reenviar convite
                    </Dropdown.Item>
                    {new Date(invite.expiration) > new Date() &&
                        invite.status === "pending" && (
                            <Dropdown.Item
                                className="text-danger font-semi-bold text-center"
                                onClick={() => deleteData(invite, "invite")}
                            >
                                Deletar convite
                            </Dropdown.Item>
                        )}
                </CardListBodyItemOptions>
            </Row>
        </CardListBody>
    );

    const updateProfessionalDetails = (updatedProfessional) => {
        setUser(updatedProfessional);
    };

    return (
        <>
            <Sidebar pageName="Profissionais" pageUrl="/profissionais">
                <Container fluid>
                    <FilterBar
                        config={filterConfig}
                        filters={filters}
                        onFilterChange={handleInputSearch}
                        onAddClick={() => {
                            setUser({});
                            setshowModalEditAdd(true);
                        }}
                        onAddText={"Convidar Profissional"}
                    />
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <DataList
                                    items={data}
                                    headers={[
                                        {
                                            label: "Nome",
                                            xs: 12,
                                            lg: 3,
                                            sortKey: "fullName",
                                            onSort: handleSort,
                                        },
                                        {
                                            label: "E-mail",
                                            xs: 12,
                                            lg: 2,
                                        },
                                        {
                                            label: "Profissão",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Data de Entrada",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Status",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Gerenciar",
                                            xs: 12,
                                            lg: 1,
                                            className: "text-center",
                                        },
                                    ]}
                                    renderItem={(item, index) =>
                                        renderItem(
                                            item,
                                            index,
                                            setUser,
                                            deleteData
                                        )
                                    }
                                />
                            </CardList>
                        </Col>
                    </Row>
                    <hr className="hr-page" />
                    <Row>
                        <Col xs={12}>
                            <h5 className="color-dark-gray mx-3 text-end">
                                Profissionais Convidados
                            </h5>
                        </Col>
                        <Col xs={12}>
                            <CardList>
                                <DataList
                                    items={invites}
                                    headers={[
                                        {
                                            label: "Nome",
                                            xs: 12,
                                            lg: 2,
                                        },
                                        {
                                            label: "E-mail",
                                            xs: 12,
                                            lg: 2,
                                        },
                                        {
                                            label: "Data do Convite",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Data de Expiração",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Status",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                        {
                                            label: "Gerenciar",
                                            xs: 12,
                                            lg: 2,
                                            className: "text-center",
                                        },
                                    ]}
                                    renderItem={renderInviteItem}
                                />
                            </CardList>
                        </Col>
                    </Row>
                </Container>
                <CustomModal
                    show={showModalView}
                    onHide={() => {
                        refreshItems();
                    }}
                    title="Detalhes do Profissional"
                    errorMessage={errorMessage}
                    type="view"
                    data={user}
                >
                    <ProfessionalsData
                        professional={user}
                        setLoading={setLoading}
                        updateProfessionalDetails={updateProfessionalDetails}
                    />
                </CustomModal>
                <CustomModal
                    show={showModalEditAdd}
                    onHide={() => {
                        refreshItems();
                    }}
                    title={
                        user._id
                            ? "Editar Usuário"
                            : invitation._id
                            ? "Editar Convite"
                            : "Convidar Usuário"
                    }
                    submitLabel={
                        user._id
                            ? "Salvar Alterações"
                            : invitation._id
                            ? "Reenviar Convite"
                            : "Convidar Usuário"
                    }
                    onSubmit={submitData}
                    errorMessage={errorMessage}
                    type="form"
                >
                    {user._id ? (
                        <UserForm
                            user={user}
                            handleInputChange={handleInputChange("users")}
                            type={"user"}
                        />
                    ) : (
                        <UserForm
                            user={invitation}
                            handleInputChange={handleInputChange("invites")}
                            type={"invite"}
                        />
                    )}
                </CustomModal>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default Professionals;
