import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { CustomModal } from "../../../components/Modal";
import InvitationForm from "../../../components/Forms/Dashboard/Invitations";
import FilterBar from "../../../components/FiltersBar";
import invitationService from "../../../services/invitationService";
import Swal from "sweetalert2";

function Invitations() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataInfo, setDataInfo] = useState({});
    const [filters, setFilters] = useState({
        page: 1,
        sort: { sortBy: "createdAt", order: "desc" },
        limit: 20,
        search: "",
        status: "all",
    });
    const [item, setItem] = useState({
        legalName: "",
        cnpj: "",
        email: "",
        userName: "",
    });
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const filterConfig = [
        {
            type: "text",
            label: "Pesquisar pelo nome do usuário",
            name: "search",
            size: 3,
        },
        {
            type: "select",
            label: "Filtrar por status",
            name: "status",
            options: [
                { label: "Todos", value: "all" },
                { label: "Pendentes", value: "pending" },
                { label: "Aceitos", value: "accepted" },
                { label: "Rejeitados", value: "rejected" },
            ],
            size: 2,
        },
        {
            type: "select",
            label: "# itens",
            name: "limit",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
            ],
            size: 1,
        },
    ];

    const refreshItems = async () => {
        setshowModalEditAdd(false);
        setLoading(false);
        setItem({});
        setErrorMessage("");
    };

    const fetchInvitations = useCallback(async () => {
        setLoading(true);
        try {
            const { page, sort, limit, search, status } = filters;
            const params = {
                page,
                sortBy: sort.sortBy,
                order: sort.order,
                limit,
                search,
                status,
            };
            const response = await invitationService.fetchData(params);
            setData(response.docs);
            setDataInfo({ ...response });
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        } finally {
            refreshItems();
        }
    }, [filters]);

    useEffect(() => {
        document.title = "We Care Sync - Convites";
        fetchInvitations();
    }, [fetchInvitations]);

    const handleSort = (sortKey) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                sort: {
                    sortBy: sortKey,
                    order:
                        prevFilters.sort.sortBy === sortKey &&
                        prevFilters.sort.order === "asc"
                            ? "desc"
                            : "asc",
                },
            };
            return newFilters;
        });
    };

    const prevPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
        }));
    };

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page < dataInfo.pages
                    ? prevFilters.page + 1
                    : prevFilters.page,
        }));
    };

    const handleInputSearch = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    const submitData = async () => {
        setLoading(true);
        try {
            if (item._id) {
                await invitationService.submitData("update", item);
            } else {
                await invitationService.submitData("add", item);
            }
            fetchInvitations();
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
        } finally {
            setLoading(false);
        }
    };

    const deleteData = async (data) => {
        const result = await Swal.fire({
            title: "Atenção!",
            text: `Deseja realmente alterar o status do convite?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                await invitationService.deleteData(data._id);
                fetchInvitations();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao tornar convite ${
                        item.status === "pending" ? "rejeitado" : "pendente"
                    }, tente novamente mais tarde.`,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Sidebar pageName="Convites" pageUrl="/invitations">
                <Container fluid>
                    <FilterBar
                        config={filterConfig}
                        filters={filters}
                        onFilterChange={handleInputSearch}
                        onAddClick={() => {
                            setItem({});
                            setshowModalEditAdd(true);
                        }}
                    />
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"userName"}
                                        >
                                            Proprietário
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            E-mail
                                        </CardListHeaderItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"legalName"}
                                        >
                                            Clínica
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.userName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"E-mail:"}
                                                value={data.email}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Clínica:"}
                                                value={data.legalName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center small ${
                                                    (data.status ===
                                                        "accepted" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "rejected" &&
                                                        "fw-bold text-danger") ||
                                                    (data.status ===
                                                        "pending" &&
                                                        "fw-bold text-warning")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    (data.status ===
                                                        "accepted" &&
                                                        "Aceito") ||
                                                    (data.status ===
                                                        "rejected" &&
                                                        "Rejeitado") ||
                                                    (data.status ===
                                                        "pending" &&
                                                        "Pendente")
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setItem(data);
                                                        setshowModalEditAdd(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                {data.status !== "accepted" && (
                                                    <>
                                                        <Dropdown.Item
                                                            className="text-danger font-semi-bold text-center"
                                                            onClick={() =>
                                                                deleteData(data)
                                                            }
                                                        >
                                                            {data.status ===
                                                            "pending"
                                                                ? "Inativar convite"
                                                                : "Ativar convite"}
                                                        </Dropdown.Item>
                                                    </>
                                                )}
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
                <CustomModal
                    show={showModalEditAdd}
                    onHide={() => {
                        refreshItems();
                    }}
                    title={item._id ? "Editar Convite" : "Adicionar Convite"}
                    submitLabel={
                        item._id ? "Salvar Alterações" : "Adicionar Convite"
                    }
                    onSubmit={submitData}
                    errorMessage={errorMessage}
                    type="form"
                    disabled={item.status === "accepted" ? true : false}
                >
                    <InvitationForm
                        item={item}
                        handleInputChange={handleInputChange}
                    />
                </CustomModal>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default Invitations;
