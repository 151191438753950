import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Alert, Form } from "react-bootstrap";
import Loading from "../../../components/Loading";
import authService from "../../../services/authService";
import inviteService from "../../../services/inviteService";
import { ButtonPrimary, ButtomCustom } from "../../../components/Buttons";
import { InviteUserForm } from "../../../components/Forms/Invites/Users";

function UserInvites() {
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorCard, setErrorCard] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Erro ao buscar convite.");
    const [errorCardMessage, setErrorCardMessage] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

    const { token } = useParams();

    useEffect(() => {
        document.title = "We Care Sync - Convite";

        const checkAuth = async () => {
            if (localStorage.getItem("isAuthenticated") === "true") {
                window.location.href = "/dashboard";
            }
        };

        const searchByToken = async (token) => {
            setIsLoading(true);
            await authService
                .getUserInvitationInfo(token)
                .then((response) => {
                    setItem(response);
                })
                .catch((error) => {
                    setError(true);
                    setErrorMessage(error);
                });
            setIsLoading(false);
        };

        checkAuth();
        searchByToken(token);
    }, [token]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        setItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    const submitData = async () => {
        setIsLoading(true);
        setErrorCard(false);
        setErrorCardMessage("");
        try {
            if (!item.userExists && item.password !== item.confirmPassword) {
                setErrorCard(true);
                setErrorCardMessage("Senhas não conferem");
                setIsLoading(false);
            } else if (!item.userExists && !hasAcceptedTerms) {
                setErrorCard(true);
                setErrorCardMessage(
                    "Você deve aceitar os termos e condições para se registrar."
                );
                setIsLoading(false);
                return;
            } else {
                await inviteService
                    .userAcceptInvitation(item._id, item)
                    .then(() => {
                        setSuccess(true);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setErrorCard(true);
                        setIsLoading(false);
                        setErrorCardMessage(error.message);
                    });
            }
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
        }
    };

    return (
        <div className="bg-color-primary">
            <Row className="min-vh-100 m-0">
                <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center align-items-center"
                >
                    <Card className="card-form py-1 px-2 card-invitation">
                        <Card.Body className="body-invitation">
                            {!error ? (
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h4>
                                            Você foi convidado para ser{" "}
                                            <strong className="color-primary">
                                                {item.profession}
                                            </strong>{" "}
                                            na clínica{" "}
                                            <strong className="color-primary">
                                                {item.clinic &&
                                                    item.clinic.legalName}
                                            </strong>
                                        </h4>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                submitData();
                                            }}
                                            className="mt-4 text-start"
                                        >
                                            {error && (
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        className="d-flex justify-content-center"
                                                    >
                                                        <Alert
                                                            variant="danger"
                                                            className="mb-0 text-center small w-auto"
                                                        >
                                                            {errorMessage}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )}

                                            {!success && !item.userExists && (
                                                <InviteUserForm
                                                    item={item}
                                                    handleInputChange={
                                                        handleInputChange
                                                    }
                                                    hasAcceptedTerms={
                                                        hasAcceptedTerms
                                                    }
                                                    setHasAcceptedTerms={
                                                        setHasAcceptedTerms
                                                    }
                                                />
                                            )}
                                            {errorCard && (
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        className="d-flex justify-content-center"
                                                    >
                                                        <Alert variant="danger">
                                                            {errorCardMessage}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                {!success && (
                                                    <Col
                                                        xs={12}
                                                        className="d-flex justify-content-center"
                                                    >
                                                        <ButtonPrimary
                                                            type="submit"
                                                            btnText={
                                                                "Aceitar convite"
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="d-flex justify-content-center align-items-center mb-4">
                                            <span className="material-icons text-danger text-center fw-bold">
                                                warning
                                            </span>
                                        </div>
                                        <h4 className="fw-bold">
                                            {errorMessage}
                                        </h4>
                                        <h5>
                                            Por favor, entre em contato com o
                                            suporte.
                                        </h5>
                                    </Col>
                                </Row>
                            )}
                            {success && (
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="d-flex justify-content-center align-items-center mb-4">
                                            <span className="material-icons text-success text-center fw-bold">
                                                check_circle
                                            </span>
                                        </div>
                                        <h4 className="fw-bold">
                                            Seu acesso foi {item.userExists ? "vinculado" : "criado"}! <br />
                                            Foram enviadas as informações para
                                            seu e-mail, e agora poderá acessar o
                                            sistema.
                                        </h4>
                                        <div className="d-flex justify-content-center align-items-center mt-4">
                                            <ButtomCustom
                                                type="button"
                                                onClick={() =>
                                                    (window.location.href =
                                                        "/login")
                                                }
                                                btnText="ir para login"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Card.Body>
                        <Card.Footer className="bg-color-white border-0 text-center">
                            <p className="mb-0">
                                Precisa de ajuda? Entre em contato com o nosso{" "}
                                <strong>
                                    <a
                                        href="https://wa.me/41999822211?text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20cadastro%20na%20We%20Care%20Sync."
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        suporte
                                    </a>
                                </strong>
                                .
                            </p>
                            <small className="color-dark-gray text-center mt-5">
                                © {new Date().getFullYear()} - We Care Sync.
                                Todos os Direitos Reservados. Criado por{" "}
                                <a
                                    className="color-dark-gray font-semi-bold text-decoration-none"
                                    href="https://starchild.com.br"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    starchild
                                </a>
                                .
                            </small>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Loading show={isLoading} />
        </div>
    );
}

export default UserInvites;
