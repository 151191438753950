import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { formatDateForInput } from "../../../../config/utils";

const UserForm = ({ user, handleInputChange }) => {
    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nome
                            {!user._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            defaultValue={user.fullName || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nível de Acesso
                            {!user._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="role"
                            defaultValue={user.role || ""}
                            onChange={handleInputChange}
                            disabled={
                                user._id && user.role === "user" ? true : false
                            }
                            required
                        >
                            <option value="" disabled>
                                Selecione o nível
                            </option>
                            {[
                                {
                                    name: "Usuário",
                                    value: "user",
                                },
                                {
                                    name: "Administrador",
                                    value: "admin",
                                },
                            ].map((item, index) => (
                                <option value={item.value} key={index}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            E-mail
                            {!user._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            defaultValue ={user.email || ""}
                            onChange={handleInputChange}
                            required
                            disabled={user._id ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Senha
                            {!user._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={user.password || ""}
                            onChange={handleInputChange}
                            placeholder={
                                user._id && "Senha oculta por segurança."
                            }
                            required
                        />
                        {user._id && (
                            <sup className="text-danger fw-bold">
                                Deixe como está pra manter a senha atual
                            </sup>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CPF
                            {!user._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="cpf"
                            value={user.cpf || ""}
                            onChange={handleInputChange}
                            required
                            disabled={user._id ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Data de Nascimento
                        </Form.Label>
                        <Form.Control
                            type="date"
                            name="birthDate"
                            defaultValue={
                                formatDateForInput(user.birthDate) || ""
                            }
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Telefone
                        </Form.Label>
                        <Form.Control
                            type="phone"
                            name="phone"
                            defaultValue={user.phone || ""}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default UserForm;
