import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const ScheduleForm = ({ item, days, handleInputChange }) => {
    return (
        <Row>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3 mb-md-2">
                    <Form.Label className="text-uppercase">
                        Duração das Sessões (em minutos)
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        name="timeOfSession"
                        min={1}
                        max={60}
                        value={item.timeOfSession || ""}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3 mb-md-2">
                    <Form.Label className="text-uppercase">
                        Início de Atendimento
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                        type="time"
                        name="startTime"
                        value={item.startTime || ""}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3 mb-md-2">
                    <Form.Label className="text-uppercase">
                        Final de Atendimento
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                        type="time"
                        name="endTime"
                        value={item.endTime || ""}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
            </Col>
            <Col xs={12} md={12} className="mt-3">
                <Form.Group className="mb-3 mb-md-2">
                    <Form.Label className="text-uppercase"></Form.Label>
                    <Form.Check
                        type="checkbox"
                        name="lunchTime"
                        label={`Há horário de almoço${" "}
                        ${
                            days.length > 1
                                ? "nos dias selecionados?"
                                : "no dia selecionado?"
                        }`}
                        checked={item.lunchTime || false}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            </Col>
            {item.lunchTime && (
                <>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3 mb-md-2">
                            <Form.Label className="text-uppercase">
                                Início de Almoço
                            </Form.Label>
                            <Form.Control
                                type="time"
                                name="lunchStartTime"
                                value={item.lunchStartTime || ""}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3 mb-md-2">
                            <Form.Label className="text-uppercase">
                                Fim de Almoço
                            </Form.Label>
                            <Form.Control
                                type="time"
                                name="lunchEndTime"
                                value={item.lunchEndTime || ""}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default ScheduleForm;
