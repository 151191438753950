import api from "../config/api.js";

const fetchData = async ({ page, sortBy, order, limit, search, status }) => {
    const response = await api.get(
        `/invitations?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}`
    );
    return response.data;
};

const submitData = async (type, data) => {
    if (type === "add") {
        try {
            const response = await api.post("/invitations", data);
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    } else {
        try {
            const response = await api.put(`/invitations/${data._id}`, data);
            return response.data;
        } catch (error) {
            console.log(error.response.data);
            throw error.response.data;
        }
    }
};

const deleteData = async (id) => {
    try {
        const response = await api.delete(`/invitations/${id}`);
        return response.data;
    } catch (error) {
        console.log(error.response.data);
        throw error.response.data;
    }
}

const apiMethods = { fetchData, submitData, deleteData };
export default apiMethods;