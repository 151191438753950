import api from "../config/api.js";

const login = async (email, password) => {
    const response = await api.post("/auth/login", { email, password });
    return response.data;
};

const emailInfo = async (user) => {
    const response = await api.post("/auth/forgot-email", user);
    return response.data;
};

const passwordResetToken = async (user) => {
    const response = await api.post("/auth/forgot-password", user);
    return response.data;
};

const logout = async () => {
    try {
        await api.post("/auth/logout");
    } catch (error) {
        console.error("Erro durante o logout:", error);
    } finally {
        localStorage.clear();
        window.location.href = "/login";
    }
};

const getUserInfo = async () => {
    let response;
    if (localStorage.getItem("isAuthenticated") === "true") {
        response = await api.get("/users/me");
        return response.data;
    } else {
        return { success: false };
    }
};

const getInvitationInfo = async (id) => {
    let response;
    try {
        response = await api.get(`/invitations/${id}`);
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
};

const getUserInvitationInfo = async (id) => {
    let response;
    try {
        response = await api.get(`/users-invitations/${id}`);
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
};

const getPasswordResetToken = async (token) => {
    const response = await api.get(`/auth/forgot-password/${token}`);
    return response.data;
};

const resetPassword = async (item) => {
    const { token } = item;
    const response = await api.put(`/auth/forgot-password/${token}`, item);
    return response.data;
};

const apiMethods = {
    login,
    emailInfo,
    passwordResetToken,
    logout,
    getUserInfo,
    getInvitationInfo,
    getUserInvitationInfo,
    getPasswordResetToken,
    resetPassword,
};
export default apiMethods;
