import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const InvitationsForm = ({ item, handleInputChange }) => {
    return (
        <>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Razão Social
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="legalName"
                            value={item.legalName || ""}
                            onChange={handleInputChange}
                            required
                            disabled={item.status === "accepted" ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            CNPJ
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="cnpj"
                            value={item.cnpj || ""}
                            onChange={handleInputChange}
                            required
                            disabled={item.status === "accepted" ? true : false}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-0 mb-md-3">
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            Nome do Proprietário
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="userName"
                            value={item.userName || ""}
                            onChange={handleInputChange}
                            required
                            disabled={item.status === "accepted" ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">
                            E-mail
                            {!item._id && (
                                <sup className="ms-1 text-danger fw-bold">
                                    *
                                </sup>
                            )}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={item.email || ""}
                            onChange={handleInputChange}
                            required
                            disabled={item.status === "accepted" ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                        <Form.Label className="text-uppercase">CPF</Form.Label>
                        <Form.Control
                            type="text"
                            name="cpf"
                            value={item.cpf || ""}
                            onChange={handleInputChange}
                            disabled={item.status === "accepted" ? true : false}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default InvitationsForm;
