import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { CustomModal } from "../../../components/Modal";
import { cnpjFormat, formatPhone } from "../../../config/utils";
import ClinicForm from "../../../components/Forms/Dashboard/Clinics";
import FilterBar from "../../../components/FiltersBar";
import clinicService from "../../../services/clinicService";
import configService from "../../../services/configService";
import Swal from "sweetalert2";

function Clinics() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataInfo, setDataInfo] = useState({});
    const [filters, setFilters] = useState({
        page: 1,
        sort: { sortBy: "createdAt", order: "desc" },
        limit: 20,
        search: "",
        status: "all",
    });
    const [item, setItem] = useState({
        legalName: "",
        cnpj: "",
        phone: "",
    });
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [zipcode, setZipcode] = useState("");

    const filterConfig = [
        {
            type: "text",
            label: "Pesquisar pelo nome da clínica",
            name: "search",
            size: 3,
        },
        {
            type: "select",
            label: "Filtrar por status",
            name: "status",
            options: [
                { label: "Todos", value: "all" },
                { label: "Ativos", value: "active" },
                { label: "Inativos", value: "inactive" },
            ],
            size: 2,
        },
        {
            type: "select",
            label: "# itens",
            name: "limit",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
            ],
            size: 1,
        },
    ];

    const refreshItems = async () => {
        setshowModalEditAdd(false);
        setLoading(false);
        setItem({});
        setErrorMessage("");
        setZipcode("");
    };

    const fetchClinics = useCallback(async () => {
        setLoading(true);
        try {
            const { page, sort, limit, search, status } = filters;
            const params = {
                page,
                sortBy: sort.sortBy,
                order: sort.order,
                limit,
                search,
                status,
            };
            const response = await clinicService.fetchData(params);
            setData(response.docs);
            setDataInfo({ ...response });
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        } finally {
            refreshItems();
        }
    }, [filters]);

    useEffect(() => {
        document.title = "We Care Sync - Clínicas";
        fetchClinics();
    }, [fetchClinics]);

    const handleSort = (sortKey) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                sort: {
                    sortBy: sortKey,
                    order:
                        prevFilters.sort.sortBy === sortKey &&
                        prevFilters.sort.order === "asc"
                            ? "desc"
                            : "asc",
                },
            };
            return newFilters;
        });
    };

    const prevPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
        }));
    };

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page < dataInfo.pages
                    ? prevFilters.page + 1
                    : prevFilters.page,
        }));
    };

    const handleInputSearch = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        if (name === "zipcode") {
            const cleanedValue = value.replace(/[^0-9]/g, "").slice(0, 8);
            setZipcode(cleanedValue);

            if (cleanedValue.length === 8) {
                setLoading(true);
                try {
                    const response = await configService.getAddressByZipcode(
                        cleanedValue
                    );
                    if (!response.erro) {
                        setItem((prevItem) => ({
                            ...prevItem,
                            addressStreet: response.logradouro,
                            addressNeighborhood: response.bairro,
                            addressCity: response.localidade,
                            addressState: response.uf,
                            addressZipCode: cleanedValue,
                        }));
                    } else {
                        setErrorMessage("CEP não encontrado");
                    }
                } catch (error) {
                    setErrorMessage(error.message);
                }
                setLoading(false);
            } else {
                setErrorMessage("");
                setItem((prevItem) => ({
                    ...prevItem,
                    addressStreet: "",
                    addressNeighborhood: "",
                    addressCity: "",
                    addressState: "",
                }));
            }
        } else {
            setItem((prevItem) => ({
                ...prevItem,
                [name]: value,
            }));
        }
    };

    const submitData = async () => {
        setLoading(true);
        try {
            if (item._id) {
                await clinicService.submitData("update", item);
            } else {
                await clinicService.submitData("add", item);
            }
            fetchClinics();
        } catch (error) {
            setErrorMessage(
                error.message ? error.message : "Erro interno do servidor."
            );
        } finally {
            setLoading(false);
        }
    };

    const deleteData = async (data) => {
        const result = await Swal.fire({
            title: "Atenção!",
            text: `Deseja realmente ${
                data.status === "active" ? "inativar" : "ativar"
            } a clínica?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                await clinicService.deleteData(data._id);
                fetchClinics();
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao ${
                        item.status === "active" ? "inativar" : "ativar"
                    } a clínica, tente novamente mais tarde.`,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Sidebar pageName="Clínicas" pageUrl="/clinics">
                <Container fluid>
                    <FilterBar
                        config={filterConfig}
                        filters={filters}
                        onFilterChange={handleInputSearch}
                        onAddClick={() => {
                            setItem({});
                            setshowModalEditAdd(true);
                        }}
                    />
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"legalName"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem xs={12} lg={3}>
                                            CNPJ
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Telefone
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={1}
                                            className="text-center"
                                        >
                                            Convidada?
                                        </CardListHeaderItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={1}
                                            onSort={handleSort}
                                            className="justify-content-center"
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.legalName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"CNPJ:"}
                                                value={cnpjFormat(data.cnpj)}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Telefone:"}
                                                value={formatPhone(data.phone)}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={1}
                                                className={`d-inline-flex align-items-center justify-content-center small fw-bold ${
                                                    data.wasInvited
                                                        ? "text-success"
                                                        : "text-danger"
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    data.wasInvited
                                                        ? "Sim"
                                                        : "Não"
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={1}
                                                className={`d-inline-flex align-items-center justify-content-center small ${
                                                    (data.status === "active" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "fw-bold text-danger")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    data.status === "active"
                                                        ? "Ativo"
                                                        : "Inativo"
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setItem(data);
                                                        setshowModalEditAdd(
                                                            true
                                                        );
                                                        setZipcode(
                                                            data.addressZipCode
                                                        );
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(data)
                                                    }
                                                >
                                                    {data.status === "active"
                                                        ? "Inativar clínica"
                                                        : "Ativar clínica"}
                                                </Dropdown.Item>
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
                <CustomModal
                    show={showModalEditAdd}
                    onHide={() => {
                        refreshItems();
                    }}
                    title={item._id ? "Editar Clínica" : "Adicionar Clínica"}
                    submitLabel={
                        item._id ? "Salvar Alterações" : "Adicionar Clínica"
                    }
                    onSubmit={submitData}
                    errorMessage={errorMessage}
                    type="form"
                >
                    <ClinicForm
                        item={item}
                        handleInputChange={handleInputChange}
                        zipcode={zipcode}
                    />
                </CustomModal>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default Clinics;
