import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./config/privateRoutes";

// Pages
import Login from "./pages/Login";
import Invites from "./pages/Invites/Clinics";
import InvitesUsers from "./pages/Invites/Users";
// import Home from "./pages/Home";
import PasswordReset from "./pages/PasswordReset";

// Dashboard
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Dashboard/Users";
import Clinics from "./pages/Dashboard/Clinics";
import Invitations from "./pages/Dashboard/Invitations";

/// Dashboard - Clinics
import Appointments from "./pages/Dashboard/_clinics/Appointments";
import MyAppointments from "./pages/Dashboard/_clinics/MyAppointments";
import Patients from "./pages/Dashboard/_clinics/Patients";
import Professionals from "./pages/Dashboard/_clinics/Professionals";
import Schedule from "./pages/Dashboard/_clinics/Schedule";

function RedirectToLogin() {
    return <Navigate to="/login" />;
}

const RootElement = () => (
    <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/convite/:token" element={<Invites />} />
        <Route path="/convite-usuario/:token" element={<InvitesUsers />} />
        <Route path="/alteracao-senha/:hash" element={<PasswordReset />} />
        {/* AUTENTICADO */}
        <Route
            path="/dashboard"
            element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            }
        />
        <Route
            path="/agenda"
            element={
                <PrivateRoute requireClinic={true}>
                    <Schedule />
                </PrivateRoute>
            }
        />
        <Route
            path="/atendimentos"
            element={
                <PrivateRoute requireClinic={true}>
                    <Appointments />
                </PrivateRoute>
            }
        />
        <Route
            path="/meus-atendimentos"
            element={
                <PrivateRoute requireClinic={true}>
                    <MyAppointments />
                </PrivateRoute>
            }
        />
        <Route
            path="/pacientes"
            element={
                <PrivateRoute requireClinic={true}>
                    <Patients />
                </PrivateRoute>
            }
        />
        <Route
            path="/profissionais"
            element={
                <PrivateRoute requireClinic={true} clinicAdminOnly={true}>
                    <Professionals />
                </PrivateRoute>
            }
        />
        {/* ADMIN */}
        <Route
            path="/users"
            element={
                <PrivateRoute adminOnly={true}>
                    <Users />
                </PrivateRoute>
            }
        />
        <Route
            path="/clinics"
            element={
                <PrivateRoute adminOnly={true}>
                    <Clinics />
                </PrivateRoute>
            }
        />
        <Route
            path="/invitations"
            element={
                <PrivateRoute adminOnly={true}>
                    <Invitations />
                </PrivateRoute>
            }
        />
        {/* 404 */}
        <Route path="*" element={<RedirectToLogin />} />
    </Routes>
);

export default RootElement;
